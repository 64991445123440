<template>
  <div class="content">
    <common-breadcrumb />
    <card-item></card-item>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'index'
};
</script>

<style scoped lang="scss">
@import 'src/pages/iebdc/styles/public';
@import 'src/pages/iebdc/styles/common-variables';
.main-list {
  margin-top: 5px;
  margin-bottom: 200px;
}
.content {
  min-height: 500px;
}
</style>
